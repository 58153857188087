<template>
  <XyeaFormEdit
    icon="enquiry"
    url="residents/"
    entityName="Enquiry"
    backRoute="residents"
    :form="form"
    :id="id"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <div class="columns">
      <CollapseCard title="Resident Details" :open.sync="detailsOpen">
        <b-field label="First Name" horizontal>
          <b-input v-model="form.firstName" required v-focus />
        </b-field>
        <b-field label="Last Name" horizontal>
          <b-input v-model="form.lastName" required />
        </b-field>

        <DateSelector v-model="form.dob" :disabled="readonly" label="Date of Birth" required :past="true" />
        <b-field label="Gender" horizontal class="field-nested">
          <PersonGender v-model="form.medicalGender" message="for medical purposes" :disabled="readonly" />
        </b-field>
        <b-field label="Dependency Level" horizontal>
          <EnumDropDown v-model="form.dependencyLevel" :data="depLevelSelect" required :disabled="readonly" />
        </b-field>
        <b-field label="Health Status" horizontal>
          <EnumDropDown v-model="form.healthStatus" :data="healthStatusSelect" required :disabled="readonly" />
        </b-field>
      </CollapseCard>

      <div class="column">
        <div class="columns">
          <CollapseCard title="Resident Address" :open.sync="addressOpen">
            <AddressEdit v-model="form" class="field" :disabled="readonly" />
          </CollapseCard>
        </div>
        <div class="columns">
          <CollapseCard title="Resident Phone Numbers" :open.sync="phonesOpen">
            <PhoneEdit
              label="Home Phone"
              :phoneNumber="form.homeNumber"
              :countryCode="form.homeCountry"
              @updatePhone="updateHomePhone"
              class="field"
              ref="homeNumber"
              :disabled="readonly"
            />
            <PhoneEdit
              label="Mobile Phone"
              :phoneNumber="form.mobileNumber"
              :countryCode="form.mobileCountry"
              @updatePhone="updateMobilePhone"
              class="field"
              ref="mobileNumber"
              :disabled="readonly"
            />
            <PhoneEdit
              label="Work Phone"
              :phoneNumber="form.workNumber"
              :countryCode="form.workCountry"
              @updatePhone="updateWorkPhone"
              class="field"
              ref="workNumber"
              :disabled="readonly"
            />
          </CollapseCard>
        </div>
      </div>
    </div>

    <div class="columns">
      <CollapseCard title="Enquiry Details" :open.sync="enquiryOpen">
        <DateSelector v-model="form.enquiryDate" :disabled="readonly" label="Enquiry Date" required :past="true" />

        <b-field label="How Received" horizontal>
          <EnumDropDown v-model="form.howReceived" :data="howReceivedSelect" required :disabled="readonly" />
        </b-field>
        <b-field label="Service Type" horizontal>
          <EnumDropDown v-model="form.serviceType" :data="serviceTypeSelect" required :disabled="readonly" />
        </b-field>
        <ResidentStatusUpdate
          :residentId="form.residentId"
          :curentStatus="form.residentStatus"
          :locationId="form.locationId"
          :currentStatusChange="form.currentStatusChange"
          :bedsInfo="form.bedsInfo"
          @add="updateCurrentStatus"
          @refreshBeds="refreshBedAssigments"
          :disabled="readonly"
        />

        <SelectLocation v-model="form.locationId" label="Location" required :mainLocationsOnly="true" />
        <NotesField v-model="form.enquiryNotes" :disabled="readonly" />
      </CollapseCard>
      <CollapseCard title="Person Enquirying" :open.sync="newEnquirerOpen">
        <b-field label="First Name" horizontal>
          <b-input v-model="form.newEnquirer.firstName" required />
        </b-field>
        <b-field label="Last Name" horizontal>
          <b-input v-model="form.newEnquirer.lastName" required />
        </b-field>
        <PhoneEdit
          label="Mobile Phone"
          :phoneNumber="form.newEnquirer.mobileNumber"
          :countryCode="form.newEnquirer.mobileCountry"
          @updatePhone="updatenewEnquirerPhone"
          class="field"
          ref="newEnquirerMobileNumber"
          :disabled="readonly"
        />
        <b-field label="Relationship" horizontal>
          <LookupSelectAdd
            v-model="form.newEnquirer.relationship"
            title="Relationship"
            :lookupType="lookupTypeRelationship"
            :disabled="readonly"
          />
        </b-field>
      </CollapseCard>
    </div>
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import SelectLocation from "@/components/SelectLocation.vue";
import CollapseCard from "@/components/CollapseCard";
import AddressEdit from "@/components/address/AddressEdit";
import PhoneEdit from "@/components/profile/PhoneEdit";
import PhoneMixin from "@/mixins/phoneMixin";
import DateSelector from "@/components/DateSelector";
import EnumDropDown from "@/components/EnumDropDown";
import LookupSelectAdd from "@/components/LookupSelectAdd.vue";
import PersonGender from "@/components/profile/PersonGender.vue";
import NotesField from "@/components/NotesField.vue";
import ResidentStatusUpdate from "@/components/profile/ResidentStatusUpdate.vue";

import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import CheckDirty from "@/mixins/checkDirty";
import ResidentEditMixin from "@/mixins/residentEditMixin";

export default {
  mixins: [CheckDirty, PhoneMixin, ResidentEditMixin],
  components: {
    XyeaFormEdit,
    SelectLocation,
    CollapseCard,
    AddressEdit,
    PhoneEdit,
    DateSelector,
    EnumDropDown,
    LookupSelectAdd,
    PersonGender,
    NotesField,
    ResidentStatusUpdate
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      locationNameReadOnly: null,
      form: this.getClearFormObject(true),
      enquiryOpen: true,
      newEnquirerOpen: true,
      detailsOpen: true,
      addressOpen: true,
      phonesOpen: false,
      lookupTypeRelationship: LookupSelectAdd.Enum.Relationship
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    readonly() {
      return !this.hasPermission(PermissionsEnum.ResidentAdd);
    }
  },

  methods: {
    updatenewEnquirerPhone(phoneInfo) {
      this.form.newEnquirer.mobileNumber = phoneInfo.phoneNumber;
      this.form.newEnquirer.mobileCountry = phoneInfo.countryCode;
      this.form.newEnquirer.mobileIsError = phoneInfo.isError;
    }
  }
};
</script>
